import {withTranslation} from '@wix/yoshi-flow-editor'
import {getUserText} from '../../../../../commons/selectors/settings'
import {getRibbonData} from '../../selectors/events'
import {getComponentConfig} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {RibbonOwnProps, RibbonRuntimeProps} from './interfaces'
import {Ribbon as Presentation} from './ribbon'

const mapRuntime = ({state}: AppProps, {event}: RibbonOwnProps): RibbonRuntimeProps => {
  const ribbonData = getRibbonData(state, event)
  return {
    ...ribbonData,
    text: getUserText(getComponentConfig(state), ribbonData.textProp as string) || ribbonData.defaultText,
  }
}

export const Ribbon = connect<RibbonOwnProps, RibbonRuntimeProps>(mapRuntime)(withTranslation()(Presentation))
export * from './interfaces'
