import {withTranslation, WithTranslation} from '@wix/yoshi-flow-editor'
import {isMobile} from '../../../../../../commons/selectors/environment'
import {getTodayButtonStyle, getTodayButtonText} from '../../../../../../commons/selectors/settings'
import {getComponentConfig} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {TodayButton as TodayButtonPresentation} from './today-button'

export interface TodayButtonOwnProps {
  onClick: () => void
  dataHook?: string
  loading?: boolean
}

export interface TodayButtonRuntimeProps {
  todayButtonText: string
  todayButtonStyle: number
  isMobile: boolean
}

export interface TodayButtonProps extends TodayButtonRuntimeProps, TodayButtonOwnProps, WithTranslation {}

const mapRuntime = ({state}: AppProps): TodayButtonRuntimeProps => {
  const componentConfig = getComponentConfig(state)

  return {
    todayButtonText: getTodayButtonText(componentConfig),
    todayButtonStyle: getTodayButtonStyle(componentConfig),
    isMobile: isMobile(state),
  }
}

export const TodayButton = connect<TodayButtonOwnProps, TodayButtonRuntimeProps>(mapRuntime)(
  withTranslation()(TodayButtonPresentation),
)
