import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMobile} from '../../../../../commons/selectors/environment'
import {isListLayout} from '../../../../../commons/selectors/settings'
import {getEvents} from '../../selectors/events'
import {isNarrow} from '../../selectors/list-settings'
import {getComponentConfig} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {ListLayoutStateProps} from './interfaces'
import {ListLayout as ListLayoutPresentation} from './list-layout'

const mapRuntime = ({state, host: {dimensions}}: AppProps): ListLayoutStateProps => ({
  events: getEvents(state),
  narrow: isNarrow(state, dimensions),
  mobile: isMobile(state),
  listLayout: isListLayout(getComponentConfig(state)),
})

export const ListLayout = connect<{}, ListLayoutStateProps>(mapRuntime)(withTranslation()(ListLayoutPresentation))

export * from './interfaces'
