import {isWithoutRegistration} from '@wix/wix-events-commons-statics'
import {isMobile} from '../../../../../commons/selectors/environment'
import {getListButtonStyle} from '../../../../../commons/selectors/settings'
import {getRSVPButtonTextForEventListItem, hasEventDetailsUrl} from '../../selectors/events'
import {getComponentConfig} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {RsvpButton as RsvpButtonPresentation} from './rsvp-button'
import {ButtonOwnProps, ButtonStateProps} from './interfaces'

const mapRuntime = (
  {state, actions, baseUrl, pageUrl, queryParams}: AppProps,
  {event}: ButtonOwnProps,
): ButtonStateProps => ({
  navigateToPage: () => actions.navigateToPage(event),
  mobile: isMobile(state),
  text: getRSVPButtonTextForEventListItem(state, event),
  style: getListButtonStyle(getComponentConfig(state)),
  withoutRegistration: isWithoutRegistration(event),
  hasUrl: hasEventDetailsUrl(state, event),
})

export const RsvpButton = connect<ButtonOwnProps, ButtonStateProps>(mapRuntime)(RsvpButtonPresentation)

export * from './interfaces'
