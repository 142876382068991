import {LocationType, EventType} from '@wix/events-types'
import {IWixStatic} from '@wix/yoshi-flow-editor'
import {getWidgetType} from '../../../../commons/selectors/settings'
import {getComponentConfig} from '../selectors/settings'
import {Actions, State} from '../types/state'
import {getOldValue} from './settings'

export interface OBIntro {
  OB_INTRO_DATA: OBIntroData
}

export const serializeFromOB = (
  data: OBIntroData,
): {eventData: wix.events.EventData; start: string; end: string; timezone: string} => ({
  eventData: serializeEventData(data),
  start: getStartDate(data.date),
  end: getEndDate(data.date),
  timezone: getTimezone(data.date),
})

const serializeEventData = (data: OBIntroData): wix.events.EventData => {
  const {name: title = '', description, date, media} = data

  const scheduleTbd = !!date.message

  const scheduleConfig: wix.events.ScheduleConfig = {
    scheduleTbd,
    scheduleTbdMessage: date.message?.trim?.().slice(0, 43),
    startDate: null,
    endDate: null,
    endDateHidden: scheduleTbd || !date.end,
    timeZoneId: getTimezone(date),
  }

  const location = getLocation(data)

  const mainImage: wix.common.Image = media
    ? {
        id: media.uri,
        width: Math.floor(media.width) || 1920,
        height: Math.floor(media.height) || 1080,
      }
    : null

  return {
    title: title.trim().slice(0, 120),
    description,
    scheduleConfig,
    location,
    mainImage,
  }
}

const getLocation = (data: OBIntroData): wix.events.Location => {
  const locationTbd = !!data.location.message
  const {name = '', address = '', type} = data.location
  if (type === 'ONLINE') {
    return {
      type: LocationType.ONLINE,
      name: name.trim().slice(0, 50),
    }
  }

  return {
    address: locationTbd ? null : address.trim().slice(0, 150),
    name: locationTbd ? data.location.message.trim().slice(0, 50) : name.trim().slice(0, 50),
    coordinates: locationTbd ? null : {lat: data.location.lat, lng: data.location.lng},
  }
}

interface OBIntroDateObject {
  message?: string
  start?: OBIntroDate
  end?: OBIntroDate
  timezone?: string
}

interface OBIntroDate {
  year: number
  month: number
  day: number
  hours: number
  minutes: number
}

const getStartDate = (date: OBIntroDateObject) => (date.message ? null : serializeDate(date.start))

const getEndDate = (date: OBIntroDateObject) => {
  if (date.message) {
    return null
  }

  return date.end ? serializeDate(date.end) : null
}

const getTimezone = (date: OBIntroDateObject) => (date.message ? null : date.timezone)

const serializeDate = (term: OBIntroDate) => {
  const date = `${term.year}-${padWithZero(term.month + 1)}-${padWithZero(term.day)}`
  const time = `${padWithZero(term.hours)}:${padWithZero(term.minutes)}`

  return `${date} ${time}`
}

const padWithZero = (value: number) => (value.toString().length === 1 ? `0${value}` : value)

interface OBIntroData {
  name?: string
  description?: string
  media?: {
    uri?: string
    width?: number
    height?: number
  }
  location?: {
    type?: 'VENUE' | 'ONLINE' | undefined
    message?: string
    name?: string
    address?: string
    lat?: number
    lng?: number
  }
  date?: OBIntroDateObject
  callToAction?: string
}

export const updateSiteWithObData = async (data: OBIntro, actions: Actions, state: State, Wix: IWixStatic) => {
  const {eventData, timezone, start, end} = serializeFromOB(data.OB_INTRO_DATA)
  const component: wix.events.editor.WebComponentConfig = {
    settings: {texts: {oneButton: data.OB_INTRO_DATA.callToAction, listButtonText: data.OB_INTRO_DATA.callToAction}},
    byStatus: undefined,
    byEventId: {eventId: []},
  }
  const instanceSettings: any = {
    firstTimeCompleted: true,
    defaultWidgetType: getOldValue('widgetType', getWidgetType(getComponentConfig(state))),
  }

  Wix?.Data?.Public?.remove(
    'OB_INTRO_DATA',
    null,
    () => null,
    () => null,
  )

  eventData.registrationConfig = {...eventData.registrationConfig, initialType: EventType.RSVP}

  await actions.createEvent({event: eventData, language: state.environment.language, start, end, timezone}, true)
  await actions.updateComponentDraft('updateSiteWithObData', component)
  await actions.updateSiteSettings(instanceSettings)
  window.location.reload()
}
