import classNames from 'classnames'
import React from 'react'
import {useWixSdk} from '@wix/yoshi-flow-editor'
import {Resizable} from '../../../../../commons/components/hoc/resizable'
import {Title} from '../list-title'
import {LoadMoreButton} from '../load-more-button'
import {getElementHeight} from '../../../../../commons/utils/dom'
import {ItemContainer as Item} from './item-container'
import s from './list-layout.scss'
import {ListLayoutProps} from '.'

export const ListLayout = ({events, t, narrow, mobile, listLayout}: ListLayoutProps) => {
  const {Wix} = useWixSdk()

  const style: React.CSSProperties = {
    padding: narrow ? '0 20px' : '0 30px',
    overflowY: narrow && !mobile ? 'scroll' : 'hidden',
    height: narrow && !mobile ? '100%' : 'auto',
  }
  const className = classNames(s.container, s.scrollBarColor, mobile && !listLayout ? s.cardContainer : s.listContainer)

  const resizeContainer = () => {
    const height = getElementHeight(`.${s.container}`)

    if (height) {
      Wix?.setHeight(height)
    }
  }

  return (
    <Resizable resizeFunc={resizeContainer}>
      <div className={className} style={style} data-hook="ev-list-layout">
        <Title />
        <ul>
          {events.map((event: ExtendedEvent, index: number) => (
            <Item key={event.id} event={event} t={t} lastItem={events.length - 1 === index} />
          ))}
        </ul>
        <LoadMoreButton t={t} />
      </div>
    </Resizable>
  )
}
