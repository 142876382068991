import classNames from 'classnames'
import React from 'react'
import ExpandArrow from '../../../../../../../icons/ExpandArrow'
import sc from '../../../classes.scss'
import sd from '../date/date.scss'
import s from './title.scss'
import {TitleProps} from '.'

export const Title = ({active, event, opened, listLayout, showArrow, withOverflow, mobile}: TitleProps) => {
  const classes = classNames(sc.textLineHeight, sd.listHeaderTitle, sd.color, {
    [s.listContentTitleColor]: active && listLayout,
    [s.mobile]: mobile,
    [s.overflow]: withOverflow,
  })

  return (
    <span className={classes}>
      <span className={s.title} data-hook="ev-list-item-title">
        {event.title}
      </span>
      {showArrow && (
        <span className={classNames(s.icon, {[s.reversedIcon]: opened})}>
          <ExpandArrow />
        </span>
      )}
    </span>
  )
}
